'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import dynamic from 'next/dynamic'

import { Price } from '../components/price/Price'
import { useLimitDiscount } from '../hook/useLimitDiscount/useLimitDiscount'
import type { ClientPriceProps } from '../types'

const ShowDiscountTestAB = dynamic(() =>
	import('fukku/Price/client/PriceTestAB/ShowDiscountTestAB').then(
		(mod) => mod.ShowDiscountTestAB
	)
)

export type OmnibusPriceFF = {
	enabled: boolean
	variables: {
		first_level_price_countries?: {
			countries: string[]
		}
	}
}
type ShowDiscountFF = {
	enabled: boolean
}

/**
 * @description
 * Renders a generic price with the possibility of adding crossed out prices. It is a client component
 *
 * @example
 * <ClientPrice prices={{price: 10}} />
 *
 * @example
 * <ClientPrice
 * prices={{
 * 		price: 10,
 * 		crossedOutPrices: [40, 30, 20],
 * 		hideLowestPrice: [40, 30]
 * 		discountRate: 50,
 * }}
 * horizontal
 * reverse
 * align="start"
 * additionalPriceVertical
 * hasMicrodata
 * hidePriceHistory
 * showDiscount
 * showDiscountInfo
 * classes={{
 * 		price: text.bodyL,
 * 		crossedOutPrices: '',
 * 		firstCrossedOutPrice: '',
 * 		discountRate: text.bodyS,
 * 		additionalPrice: '',
 * 		additionalFirstCrossedOutPrice: '',
 * 		additionalCrossedOutPrices: '',
 * }}
 * />
 *
 * @see {@link ServerPrice}
 */
export const ClientPrice = (props: ClientPriceProps) => {
	const { enabled, variables } = useFeatureFlag<OmnibusPriceFF>(
		featureFlagKeys.OmnibusExceptionCountries
	)
	const { enabled: showDiscountEnabled } = useFeatureFlag<ShowDiscountFF>(
		featureFlagKeys.ShowDiscount
	)

	const {
		showOmnibusPrice = true,
		showDiscount: showDiscountProp,
		prices,
	} = props

	const { channel, country, brand } = useMasterData()
	const { countryISO } = country

	const { t } = useLabels()

	const countries = variables?.first_level_price_countries?.countries ?? []
	const hideSaleOrPromoPrice =
		enabled && showOmnibusPrice && !countries.includes(countryISO.toLowerCase())

	const limitDiscount = useLimitDiscount({ channel, brand })

	// we use "showDiscountProp !== false" to ensure discount is not shown in previewCart,
	// even if FF is enabled for PLP.
	const shouldShowDiscount =
		(showDiscountEnabled && showDiscountProp !== false) || showDiscountProp

	const PriceComponent =
		shouldShowDiscount && prices.discountRate ? ShowDiscountTestAB : Price

	return (
		<PriceComponent
			{...props}
			limitDiscount={limitDiscount}
			showDiscount={showDiscountProp}
			country={country}
			hideSaleOrPromoPrice={hideSaleOrPromoPrice}
			getLabel={t}
			channel={channel}
		/>
	)
}
